import { createKaiizenSsrPage } from "~/new/lib/kaiizenAppShared";
import { css } from "@emotion/react";
import {
  SectionContainer,
  SectionNavContainer,
  SiteContainer,
} from "~/react/layout";
import { SiteNav } from "~/clients/sharedPartials";

export type ErrorViewState = {
  error: string;
  statusCode: number;
  release: string;
};

const errorContStyles = css`
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  position: relative;
  text-align: center;
  top: 30px;
  width: 50%;
`;

export const labeledErrorPageView = {
  ZenErrorPageView: ({ error, statusCode, release }: ErrorViewState) => (
    <SiteContainer>
      <SiteNav site="zen" />
      <SectionContainer>
        <SectionNavContainer />
        <div css={errorContStyles} className="container">
          <h1>
            {error} ({statusCode})
          </h1>
          <p>Release: {release}</p>
        </div>
      </SectionContainer>
    </SiteContainer>
  ),
};

export const errorSsrPage = () =>
  createKaiizenSsrPage({
    route: "/",
    load: ({ error, deps }): ErrorViewState => ({
      error: error!.message,
      statusCode: error!.statusCode,
      release: deps!.config.app.version,
    }),
    labeledView: labeledErrorPageView,
  });
