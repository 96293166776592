import {
  zenOpportunitiesListPage,
  labeledZenOpportunitiesListView,
} from "./ZenOpportunitiesList";
import {
  zenPropertiesDetailPage,
  labeledZenPropertyDetailView,
} from "./ZenPropertyDetail";
import {
  zenPropertyPhotosPage,
  labeledZenPropertyPhotosView,
} from "./ZenPropertyPhotos";
import {
  zenSubmitKaiListingSsrPage,
  labeledZenSubmitKaiListingView,
} from "./ZenSubmitKaiListing";
import {
  zenLoginWithEmailSsrPage,
  labeledZenLoginWithEmailView,
} from "./ZenLoginWithEmail";
import { errorSsrPage, labeledErrorPageView } from "./ZenErrorPage";
export { errorSsrPage };

// For the Server - Login Pages
export const zenLoginPageInitializers = () => [zenLoginWithEmailSsrPage()];

// For the Server - Authed Pages
export const zenAuthedPageInitializers = () => [
  zenOpportunitiesListPage(),
  zenPropertyPhotosPage(),
  zenSubmitKaiListingSsrPage(),
  zenPropertiesDetailPage(),
];

// For the Client - All Views
export const labeledZenViews = [
  labeledZenOpportunitiesListView,
  labeledZenPropertyDetailView,
  labeledZenPropertyPhotosView,
  labeledZenSubmitKaiListingView,
  labeledZenLoginWithEmailView,
  labeledErrorPageView,
];
