import { useRef, useState } from "react";
import { css } from "@emotion/react";

import { createKaiizenSsrPage } from "~/new/lib";
import { idRequestParamsSchema } from "~/zodLib";

import {
  getPropertySnapshotOrNotFoundError,
  getPropertyPhotos,
  getPhotoSizes,
} from "~/dataServices/ModelLib";

import { PhotoNote, Pill, Chevron } from "../widgets";
import {
  PhotoCarouselInnerNav,
  PhotoCarouselOuterNav,
  PhotoCarouselPage,
} from "../styles";
import { XIcon as CloseBtn } from "../../sharedAssets";

// TODO: modularize these dependencies
import { useMountEffect } from "~/react/ClientOnly";
import { PropertyDetailPhoto } from "~/app/pages/opportunities/types";
import { PhotoScroller } from "~/react/components/property/photo-navigator/PhotoScroller";

type LoaderResult = {
  property: { id: string; address: string };
  photos: PropertyDetailPhoto[];
};

const getPhotoFromFragment = (fragment: string) => {
  const match = fragment.match(/photo-(\d+)/);
  return match ? parseInt(match[1], 10) - 1 : 0;
};

const styles = css`
  display: flex;
  flex-direction: row;
  gap: 30px;
  height: calc(100% - 140px);
  margin: 30px;

  aside {
    display: flex;
    flex-basis: 30%;
    flex-direction: column;
    gap: 20px;

    .title {
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .tags {
      display: flex;
      gap: 8px;
      margin: 35px 0 30px 0;
      text-transform: capitalize;
    }
  }
`;

export const labeledZenPropertyPhotosView = {
  ZenPropertyPhotosView: ({
    property,
    photos,
  }: {
    property: { id: string; address: string };
    photos: PropertyDetailPhoto[];
  }) => {
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState<
      number | undefined
    >(0);
    const thumbsDiv = useRef<HTMLDivElement>(null);

    const showPhoto = (index: number) => {
      if (index < 0 || index >= photos.length) {
        return;
      }
      setCurrentPhotoIndex(index);
      const shouldSmoothScroll = currentPhotoIndex !== undefined;
      thumbsDiv.current?.children[index].scrollIntoView({
        inline: "center",
        behavior: shouldSmoothScroll ? "smooth" : "auto",
      });
      window.location.replace(`#photo-${index + 1}`);
    };

    useMountEffect(() => {
      const initialPhoto =
        typeof window === "object"
          ? getPhotoFromFragment(window.location.hash)
          : 0;
      if (initialPhoto >= 0 && initialPhoto < photos.length) {
        showPhoto(initialPhoto);
      }
    });

    let photo = currentPhotoIndex ? photos[currentPhotoIndex] : undefined;
    if (currentPhotoIndex === 0) {
      photo = photos[0];
    }

    return (
      <PhotoCarouselPage>
        <PhotoCarouselOuterNav>
          <PhotoCarouselInnerNav className="reverse">
            <div className="title">
              <a href={`/property/${property.id}`}>
                <Chevron />
              </a>
              {property.address}
            </div>
            <a href={`/property/${property.id}`} className="button--close">
              <CloseBtn />
            </a>
          </PhotoCarouselInnerNav>
        </PhotoCarouselOuterNav>
        <main id="app-photo-carousel" css={styles}>
          <PhotoScroller
            currentIndex={currentPhotoIndex}
            photos={photos}
            setCurrentIndex={setCurrentPhotoIndex}
          />
          <aside>
            {photo && photo.tags.length > 0 && (
              <div className="tags">
                {photo.tags.map((tag, idx) => (
                  <Pill type="tag" key={`${tag}-${idx}`}>
                    {tag}
                  </Pill>
                ))}
              </div>
            )}

            {photo?.kaiDescription && (
              <PhotoNote fromKaiizen={true} text={photo.kaiDescription} />
            )}
            {photo?.adminDescription && (
              <PhotoNote fromKaiizen={false} text={photo.adminDescription} />
            )}
            {!photo?.kaiDescription && !photo?.adminDescription && (
              <PhotoNote text="None yet." />
            )}
          </aside>
        </main>
      </PhotoCarouselPage>
    );
  },
};

export const zenPropertyPhotosPage = () =>
  createKaiizenSsrPage({
    route: "/property/:id/photos",
    input: idRequestParamsSchema,
    load: async ({ input, deps }): Promise<LoaderResult> => {
      const { id: propertyId } = input.params;

      return {
        photos: (await getPropertyPhotos(deps, { propertyId })).map(
          (photo) => ({
            id: photo.id,
            tags: photo.tags ?? [],
            kaiDescription: photo.kaiDescription ?? "",
            adminDescription: photo.adminDescription ?? "",
            urls: getPhotoSizes(deps, photo),
          }),
        ),
        property: {
          id: propertyId,
          address: (
            await getPropertySnapshotOrNotFoundError(deps, { propertyId })
          ).oneLineAddress,
        },
      };
    },
    labeledView: labeledZenPropertyPhotosView,
  });
