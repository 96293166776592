import { css } from "@emotion/react";
import classNames from "classnames";
import { PropertyType } from "~/lib/uiTypes";
import { useModal } from "../../sharedHooks";
import { Modal, DownloadModal, Button } from "../widgets";
import { breakpointXL } from "../styles";
import { CategoryItem } from "./CategoryItem";
import colors from "~/react/utilties/colors";

export const sidebarStyles = css`
  background-color: white;
  display: flex;
  flex-direction: column;
  //margin: var(--paddingXL);
  min-width: 240px;
  //padding: 0 var(--paddingXL);
  width: 20%;

  // @media (max-width: ${breakpointXL}) {
  //   margin-bottom: var(--paddingL);
  // }

  @media (max-width: 1350px) {
    flex-direction: row;
    gap: 50px;
    //margin: 0 0 var(--paddingL) 0;
    width: auto;
    //padding-top: 12px;
    //padding-bottom: 12px;

    > .container__head {
      display: none;
    }
  }

  // the column headings start wrapping at 1000px
  @media (max-width: 1000px) {
    gap: 30px;
  }

  h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    padding: 25px 0;

    &:after {
      background-color: ${colors["gray-divider"]};
      content: " ";
      display: block;
      height: 1px;
      position: relative;
      top: 20px;
      width: 100%;
    }
  }
`;

type PropertiesSidebarProps = {
  opportunities: PropertyType[];
  selectedCategory: PropertyListCategory;
  selectedOppIds: Set<string>;
  setSelectedCategory: (category: PropertyListCategory) => void;
};

export const propertyListCategories = [
  "new",
  "offers",
  "won",
  "lost",
  "in-progress",
  "declined",
  "price-change",
] as const;

export type PropertyListCategory = (typeof propertyListCategories)[number];

export const PropertiesSidebar = ({
  opportunities,
  selectedCategory,
  selectedOppIds,
  setSelectedCategory,
}: PropertiesSidebarProps) => {
  const downloadModalState = useModal();
  const selectedCount = selectedOppIds.size;

  const exportBtnClass = classNames({
    disabled: !selectedCount,
    teal: selectedCount,
  });

  return (
    <div css={sidebarStyles}>
      <Modal modalHook={downloadModalState}>
        {downloadModalState.isVisible && (
          <DownloadModal
            closeFunction={downloadModalState.closeModal}
            count={selectedCount}
          />
        )}
      </Modal>
      <h4>Categories</h4>
      {propertyListCategories.map((cat) => (
        <CategoryItem
          key={cat}
          category={cat}
          isSelected={cat === selectedCategory}
          count={opportunities.filter((opp) => opp.category === cat).length}
          setSelectedCategory={setSelectedCategory as (x: string) => void}
        />
      ))}

      <div className="tout">
        <div className="tout--text">
          <strong>Dive into the Details</strong>
          Explore, Analyze, Transact Opportunity Awaits!
        </div>

        <Button
          color="blue"
          className={exportBtnClass}
          onClick={downloadModalState.showModal}
          disabled={!selectedCount}
        >
          Export Data
        </Button>
      </div>
    </div>
  );
};
