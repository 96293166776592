import { useModal } from "../../sharedHooks";
import { Modal, DownloadModal } from "../widgets";
import { sidebarStyles } from "./PropertiesSidebar";
import { categoryItemStyles } from "./CategoryItem";
import { LinkButton } from "../widgets";
import { PropertySnapshot } from "~/lib/models/PropertySnapshot";
import {
  BoltIcon,
  BoxIcon,
  ClipboardIcon,
  DollarIcon,
  DoorIcon,
  HouseIcon,
  MapPinIcon,
  TreeIcon,
} from "~/assets/icons";

const categoryTabs = [
  "overview",
  "financial",
  "geographic",
  "systems",
  "interior",
  "construction",
  "property",
  "miscellaneous",
] as const;

type PropertyDetailsTab = (typeof categoryTabs)[number];

type PropertySidebarProps = {
  property: PropertySnapshot;
  selectedSection: string;
  setTab: (tab: PropertyDetailsTab) => void;
};

const iconMap: { [index: string]: any } = {
  construction: HouseIcon,
  financial: DollarIcon,
  geographic: MapPinIcon,
  interior: DoorIcon,
  miscellaneous: BoxIcon,
  overview: ClipboardIcon,
  property: TreeIcon,
  systems: BoltIcon,
};

const SideBarIcon = ({ tabName }: { tabName: string }) => {
  const SBI = iconMap[tabName];
  return <SBI />;
};

export const PropertySidebar = ({
  property,
  selectedSection,
  setTab,
}: PropertySidebarProps) => {
  const downloadModalState = useModal();

  return (
    <div className="container" css={sidebarStyles}>
      <h4>Category</h4>
      {categoryTabs.map((category) => (
        <LinkButton
          key={category}
          css={categoryItemStyles}
          className={`${category} ${
            category === selectedSection ? "active" : ""
          }`}
          onClick={() => setTab(category)}
        >
          <SideBarIcon tabName={category} /> {category}
        </LinkButton>
      ))}

      <Modal modalHook={downloadModalState}>
        {downloadModalState.isVisible && (
          <DownloadModal
            closeFunction={downloadModalState.closeModal}
            address={`${property.houseNumber} ${property.street}`}
          />
        )}
      </Modal>
    </div>
  );
};
