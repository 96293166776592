import { Pill } from "./Pill";
import { CameraIcon } from "~/assets/icons";

type PhotoPillProps = {
  onClick?: () => void;
  photoCount: number;
};

export const PhotoPill = ({ onClick, photoCount }: PhotoPillProps) => {
  return (
    <Pill clickHandler={photoCount ? onClick : () => {}} type="camera">
      {photoCount} <CameraIcon />
    </Pill>
  );
};
