import { hydrateRoot } from "react-dom/client";
import { App, ServerStatePayload } from "../kaiizenAppShared";

type Views = Record<string, React.FC>;
const getBrowserGlobal = () => window as any;

const getView = (views: Views) => {
  const viewKey = getBrowserGlobal().__kzReactView;
  const View = views[viewKey as keyof typeof views];
  if (!View)
    throw new Error(
      `No view found. viewKey: ${viewKey}. Known view keys: ${Object.keys(views).join(", ")}`,
    );
  return View;
};

const getServerState = () => {
  const serverState = getBrowserGlobal().__kzServerState as ServerStatePayload;
  if (!serverState) throw new Error("No server state found");
  return serverState;
};

const getAppContainerElement = () => {
  const container = document.getElementById("app")!;
  if (!container) throw new Error("No app container found");
  return container;
};

export const startSsrClient = (views: Views) =>
  hydrateRoot(
    getAppContainerElement(),
    <App View={getView(views)} serverState={getServerState()} />,
  );
