import colors from "~/react/utilties/colors";
import { css } from "@emotion/react";

const styles = css`
  appearance: none;
  border: none;
  border-radius: 10px;
  color: ${colors.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 3px 14px;
  text-align: center;
  width: 94px;

  &.camera {
    display: flex;
    cursor: pointer;
    background-color: ${colors.purple};
    gap: 8px;
    stroke: ${colors.white};
    width: auto;

    svg {
      position: relative;
      top: -1px;
    }
  }

  &.rollup {
    background-color: #9186e7;
    cursor: pointer;

    &--doc {
      background-color: #82d2e5;
    }
  }

  &.empty {
    background: #e2e8f0;
    color: ${colors["gray-medium"]};
    cursor: default;
  }

  &.detail {
    background-color: #cac7f4;
    color: ${colors.purple};
    cursor: pointer;
  }

  &.doc {
    background-color: #d4f1f6;
    color: #018b9e;
  }

  &.tag {
    background-color: #e3e1ff;
    border: 1px solid ${colors.purple};
    border-radius: 100px;
    color: ${colors.purple};
    font-weight: 500;
    width: auto;
  }
`;

export const Pill = ({
  type,
  clickHandler,
  children,
}: {
  type?: string;
  clickHandler?: any;
  children?: any;
}) => {
  return (
    <button css={styles} onClick={clickHandler} className={type || ""}>
      {children}
    </button>
  );
};
