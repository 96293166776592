import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { breakpointXL } from "~/react/layout";
import { PropertySnapshot } from "~/lib/models/PropertySnapshot";
import {
  formatPrice,
  formatRoomCount,
  sqftToAcres,
} from "~/react/utilties/numberFormatters";

const styles = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 0 20px;

  @media (max-width: 1350px) {
    padding: 0 0 var(--paddingL) 0;
  }

  .headline {
    align-content: center;
    color: #10045a;
    display: flex;
    font-size: var(--headlineXL);
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    span {
      color: var(--gray-medium);
      display: block;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }

    @media (max-width: ${breakpointXL}) {
      font-size: var(--headlineL);
    }

    @media (max-width: 1350px) {
      font-size: 18px;
    }
  }

  .item {
    border-right: solid 1px #e2e5f0;
    color: var(--gray-medium);
    display: inline-block;
    font-size: 16px;
    padding: 0 var(--paddingXL);

    @media (max-width: ${breakpointXL}) {
      padding: 0 var(--paddingL);
    }

    @media (max-width: 1350px) {
      font-size: 14px;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      border-right: none;
      padding-right: 0;
    }

    strong {
      color: #10045a;
      display: block;
      font-size: 22px;

      // @media (max-width: ${breakpointXL}) {
      //   font-size: 18px;
      // }
    }
  }
`;

const PaleGreenDot = styled.div`
  display: inline-block;
  background: rgba(5, 205, 153, 0.2);
  height: 30px;
  margin-right: 10px;
  position: relative;
  top: -2px;
  width: 30px;
  border-radius: 49px;

  &:before {
    content: " ";
    background-color: #05cd99;
    border-radius: 49px;
    display: block;
    height: 18px;
    left: 6px;
    position: absolute;
    top: 6px;
    width: 18px;
  }
`;
const SummaryItem = ({
  value,
  label,
}: {
  value?: string | number;
  label: string;
}) => {
  return (
    <div className="item">
      <strong>{value}</strong>
      {label}
    </div>
  );
};

export const PropertyTopSummary = ({
  property,
}: {
  property: PropertySnapshot;
}) => {
  return (
    <div css={styles}>
      <div className="headline">
        <PaleGreenDot></PaleGreenDot>
        <div>
          {property.houseNumber} {property.street}{" "}
          <span>
            {property.city}, {property.stateCode} {property.zip}
          </span>
        </div>
      </div>
      <div>
        {/*<SummaryItem value={formatPrice(property.price)} label={"price"} />*/}
        <SummaryItem
          value={formatPrice(property.state.askingPriceDollars || 0)}
          label={"price"}
        />
        <SummaryItem
          value={formatRoomCount(property.bedCount)}
          label={"beds"}
        />
        <SummaryItem
          value={formatRoomCount(property.bathCount)}
          label={"baths"}
        />
        <SummaryItem value={property.sqft?.toLocaleString()} label={"sqft"} />
        <SummaryItem value={property.year} label={"vintage"} />
        <SummaryItem value={sqftToAcres(property.lotSqft)} label={"acres"} />
        <SummaryItem
          value={property.garageType ? "yes" : "no"}
          label={"garage"}
        />
        <SummaryItem
          value={property.state.statusKaiizenVerified ? "yes" : "no"}
          label="Kai verified"
        />
      </div>
    </div>
  );
};
