import { useEffect, useRef, useState } from "react";
import { FullscreenControl, Map, Marker, NavigationControl } from "mapbox-gl";
import { css } from "@emotion/react";

const mapStyles = css`
  background-color: var(--gray-medium);
  border-radius: 20px;
  height: 100%;
  width: 100%;
`;

const mapControls = css`
  background-color: white;
  border-radius: 10px;
  font-size: 12px;
  padding: 12px;
  position: absolute;
  z-index: 5;

  display: flex;
  bottom: 20px;
  left: 20px;

  button {
    appearance: none;
    border: 0;
    background: none;
    font-family: inherit;
    font-size: 12px;
  }

  button:first-child {
    cursor: pointer;
    padding-right: 20px;
  }

  button:last-child {
    cursor: pointer;
    padding-left: 20px;
    position: relative;

    &:before {
      background-color: var(--gray-light);
      content: " ";
      display: inline-block;
      height: 160%;
      left: 0;
      position: absolute;
      top: -5px;
      width: 1px;
    }
  }

  .active {
    color: #4a25e1;
    font-weight: 600;
  }
`;

type PropertyMapProps = {
  apiKey: string;
  coords: [number, number];
};

export const PropertyMap = ({ coords, apiKey }: PropertyMapProps) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState<Map | null>(null);
  const [activeMapStyle, setActiveMapStyle] = useState("streets");

  const setMapStyle = (styleId: "streets" | "satellite") => () => {
    if (!map) {
      return;
    }
    const styles = {
      streets: "streets-v12",
      satellite: "satellite-streets-v12",
    };
    map.setStyle(`mapbox://styles/mapbox/${styles[styleId]}`);
    setActiveMapStyle(styleId);
  };

  useEffect(() => {
    if (!map && mapContainer.current) {
      const newMap = new Map({
        accessToken: apiKey,
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: coords,
        zoom: 16.25,
      });
      newMap.addControl(new FullscreenControl());
      newMap.addControl(
        new NavigationControl({
          showCompass: false,
        }),
        "bottom-right",
      );
      new Marker().setLngLat(coords).addTo(newMap);
      setMap(newMap);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div css={mapStyles} ref={mapContainer}></div>
      <div css={mapControls}>
        <button
          className={activeMapStyle === "streets" ? "active" : ""}
          onClick={setMapStyle("streets")}
        >
          Map
        </button>
        <button
          className={activeMapStyle === "satellite" ? "active" : ""}
          onClick={setMapStyle("satellite")}
        >
          Satellite
        </button>
      </div>
    </>
  );
};
