import { css } from "@emotion/react";
import FusionLogo from "~/assets/logos/fusion-logo.svg";
import { FusionWord, ZenWord } from "~/react/sitenav/wordmarks";
import DoubleChevron from "~/assets/icons/double-chevron.svg";
import { ZenLogo } from "~/clients/zen/assets";
import { createContext, ReactNode } from "react";
import colors from "~/react/utilties/colors";
import { useSitenavState } from "~/lib/hooks/cookieState";

type SiteKey = "fusion" | "zen";

type SiteNavContextType = {
  expanded: boolean | undefined;
  activePrimary: string | undefined;
  activeSecondary: string | undefined;
};

export const SiteNavContext = createContext<SiteNavContextType>({
  activePrimary: "",
  activeSecondary: "",
  expanded: false,
});

const styles = css`
  background-color: var(--blue-primary);
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: width;
  transition-duration: var(--anim-expand);
  transition-delay: 250ms;
  width: 80px;

  .logo {
    align-items: center;
    border-bottom: solid 1px rgba(233, 237, 247, 0.4);
    display: flex;
    height: 80px;
    padding-left: var(--paddingL);
    width: 100%;

    .site-logo {
      appearance: none;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;

      svg {
        height: 36px;
        width: 36px;
        transition:
          height var(--anim-expand) 220ms,
          width var(--anim-expand) 220ms;
      }
    }
  }

  .wordmark,
  .collapse-icon {
    opacity: 0;
    // fade-out before collapse, no delay
    transition: opacity var(--anim-opacity);
    visibility: hidden;
    width: 0;
  }

  .wordmark {
    left: 11px;
    position: relative;
    top: 3px;
  }

  .collapse-icon {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 3px;
    padding: 0;
    position: absolute;
    right: 18px;

    svg {
      stroke: var(--purple-light);
    }

    &:hover {
      svg {
        stroke: white;
      }
    }
  }

  &.expanded {
    transition-delay: 0ms;
    min-width: 240px;
    width: 240px;

    .site-logo svg {
      transition:
        height var(--anim-expand),
        width var(--anim-expand);
      height: 28px;
      width: 28px;
    }

    .wordmark,
    .collapse-icon {
      opacity: 1;
      // fade-in, wait for expand before beginning
      transition: opacity var(--anim-opacity) var(--anim-expand);
    }

    .wordmark {
      height: auto;
      width: auto;
    }

    .collapse-icon {
      height: 22px;
      width: 22px;
    }

    .wordmark,
    .collapse-icon {
      visibility: visible;
    }
  }

  nav {
    color: ${colors["purple-light"]};
    margin-top: 42px;
    padding-left: 26px;

    a {
      color: ${colors["purple-light"]};

      svg {
        fill: ${colors["purple-light"]};

        path {
          fill: ${colors["purple-light"]};
        }
      }
    }

    .selected {
      svg {
        fill: ${colors.white};

        path {
          fill: ${colors.white};
        }
      }
    }
  }
`;

export const SiteNav = ({
  activePrimary,
  activeSecondary,
  children,
  site,
}: {
  activePrimary?: string;
  activeSecondary?: string;
  children?: ReactNode;
  site: SiteKey;
}) => {
  const [navState, setNavState] = useSitenavState();

  const state = {
    activePrimary,
    activeSecondary,
    expanded: navState === "expanded",
  };

  const logos = {
    fusion: <FusionLogo />,
    zen: <ZenLogo />,
  };

  const wordmarks = {
    fusion: FusionWord,
    zen: ZenWord,
  };

  const Word = wordmarks[site];

  // don't expand the nav if there's nothing to show
  const expandIfPopulated = () => {
    if (children) {
      setNavState("expanded");
    }
  };

  return (
    <SiteNavContext.Provider value={state}>
      <div css={styles} className={navState}>
        <div className="logo">
          <button
            className="site-logo"
            onClick={expandIfPopulated}
            style={{ cursor: children ? "cursor" : "default" }}
            title="Expand Navigation"
          >
            {logos[site]}
          </button>
          <div className="wordmark">
            <Word />
          </div>
          <button
            className="collapse-icon"
            onClick={() => {
              setNavState("collapsed");
            }}
          >
            <DoubleChevron />
          </button>
        </div>
        {children}
      </div>
    </SiteNavContext.Provider>
  );
};
