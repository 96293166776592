import { propPriceStr } from "~/lib/format/formats";
import { formatRenoArray, PropertyAccordion } from "../index";
import { PropertySnapshot } from "~/lib/models/PropertySnapshot";
import { DetailScreen } from "~/clients/zen/partials/property/OverviewDetail";
import { PropertyMap } from "~/clients/zen/partials/PropertyMap";
import { PropertyDetailPhoto } from "~/app/pages/opportunities/types";
import { useState } from "react";
import { PhotoNavigator } from "~/react/components/property/photo-navigator/PhotoNavigator";

export const FinancialDetail = ({
  property,
  photos,
  mapboxKey,
  renoTotals,
}: {
  property: PropertySnapshot;
  photos: PropertyDetailPhoto[];
  mapboxKey: string;
  renoTotals: { [index: string]: number[] };
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const { state: propVals } = property;

  return (
    <DetailScreen className="container">
      <h4>Financial</h4>
      <div className="wrap">
        <div className="left">
          <PropertyAccordion
            title="Kaiizen Price"
            // titleValue={propPriceStr(fin.askingPrice.value + fin.kaiizenFee.value)}
            titleValue={"1"}
            expandedDefault={true}
            rows={[
              {
                name: "Asking Price",
                value: propPriceStr(propVals.askingPriceDollars),
              },
              {
                name: "Kaiizen Fee",
                value: propPriceStr(propVals.feesKaiizenFeeDollars),
              },
              {
                name: "Renovation",
                value: renoTotals.total,
              },
              // {
              //   name: "Closing Costs",
              //   value: propPriceStr(1),
              // },
            ]}
          />
          <PropertyAccordion
            title="Occupancy"
            // titleValue={propPriceStr(1)}
            rows={[
              // {
              //   name: "Est. Rent",
              //   value: propPriceStr(1) + " / mo.",
              // },
              // {
              //   name: "Effective Rent",
              //   value: "1",
              //   // fin.effectiveRent.value !== null
              //   //   ? propPriceStr(fin.effectiveRent.value)
              //   //   : "N/A",
              // },
              {
                name: "Occupied",
                // value: fin.occupied.value ? "Yes" : "No",
                value: propVals.occupancyIsOccupied ? "Yes" : "No",
              },
              {
                name: "Lease End Date",
                value: propVals.occupancyLeaseEndDate
                  ? new Date(propVals.occupancyLeaseEndDate).toLocaleDateString(
                      "en-US",
                    )
                  : "",
                // value: fin.leaseEndDate.value
                //   ? dateStr(fin.leaseEndDate.value)
                //   : "N/A",
              },
            ]}
          />
          {/*<PropertyAccordion*/}
          {/*  title="Est. OPEX"*/}
          {/*  // titleValue={propPriceStr(opexTotal) + " / mo."}*/}
          {/*  titleValue={propPriceStr(1) + " / mo."}*/}
          {/*  rows={[*/}
          {/*    {*/}
          {/*      name: "OPEX",*/}
          {/*      value: propPriceStr(1) + " / mo.",*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "OPEX % of Rent",*/}
          {/*      value: precisePctStr(1),*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "Property Taxes",*/}
          {/*      // value: propPriceStr(fin.opexPropertyTaxesMonth.value) + " / mo.",*/}
          {/*      value: propPriceStr(1) + " / mo.",*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "Insurance",*/}
          {/*      value: propPriceStr(1) + " / mo.",*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "Maintenance",*/}
          {/*      value: propPriceStr(1) + " / mo.",*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "Other",*/}
          {/*      value: propPriceStr(1) + " / mo.",*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*/>*/}
          {/*<PropertyAccordion*/}
          {/*  title="Estimated Cap Rate"*/}
          {/*  titleValue={precisePctStr(1 / 100)}*/}
          {/*  // titleValue={precisePctStr(fin.capRate.value / 100)}*/}
          {/*  rows={[*/}
          {/*    {*/}
          {/*      name: "Cap Rate",*/}
          {/*      value: precisePctStr(1 / 100),*/}
          {/*      // value: precisePctStr(fin.capRate.value / 100),*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "Gross Income",*/}
          {/*      value: propPriceStr(1) + " / year",*/}
          {/*      // value: propPriceStr(fin.grossIncomeYear.value) + " / year",*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "NOI",*/}
          {/*      value: propPriceStr(1) + " / year",*/}
          {/*      // value: propPriceStr(fin.noiYear.value) + " / year",*/}
          {/*    },*/}
          {/*    {*/}
          {/*      name: "NOI Margin",*/}
          {/*      value: precisePctStr(1),*/}
          {/*      // value: precisePctStr(fin.noiYear.value / fin.grossIncomeYear.value),*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*/>*/}
          <PropertyAccordion
            title="Renovation"
            titleValue={formatRenoArray(renoTotals.total)}
            // titleValue={fin.renoStatus.value}
            rows={[
              {
                name: "Systems",
                value: formatRenoArray(renoTotals.systems),
              },
              {
                name: "Interior",
                value: formatRenoArray(renoTotals.interior),
              },
              {
                name: "Construction",
                value: formatRenoArray(renoTotals.construction),
              },
              {
                name: "Property",
                value: formatRenoArray(renoTotals.property),
              },
              {
                name: "Miscellaneous",
                value: formatRenoArray(renoTotals.miscellaneous),
              },
            ]}
          />
        </div>
        <div className="right">
          <PhotoNavigator
            photos={photos}
            currentIndex={photoIndex}
            onPhotoChangeClick={setPhotoIndex}
            photoBackgroundSize={"contain"}
            paginationAlign={"right"}
            onSeeAllClick={() => {
              window.location.assign(`/property/${property.id}/photos`);
            }}
          />
          <PropertyMap
            apiKey={mapboxKey}
            coords={property.state.coordinates || [0, 0]}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
