import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import { propPriceStr } from "~/lib/format/formats";
import { propertyEditSection } from "~/fusion/components/properties/PropertyEditNav";

export const PROPERTY_CONDITION_SCREENS = [
  // NOTE: this defines the presented order in the client
  "systems",
  "interior",
  "construction",
  "property",
  "miscellaneous",
] as const;

export const propertyConditionScreenZod = z.enum(PROPERTY_CONDITION_SCREENS);
export type PropertyConditionScreen = z.infer<
  typeof propertyConditionScreenZod
>;

export const PROPERTY_DETAILS_SCREENS = [
  // NOTE: this defines the presented order in the client
  "overview",
  "financials",
  "geographic",
  "systems",
  "interior",
  "construction",
  "property",
  "miscellaneous",
] as const;

export const propertyDetailScreenZod = z.enum(PROPERTY_DETAILS_SCREENS);
export type PropertyDetailScreen = z.infer<typeof propertyDetailScreenZod>;

const overviewFields = [
  "propertyOverviewDescription",
  "status-KaiizenVerified",
  "status-ZenEligible",
  "status-Portfolio",
  "soldAs",
  "submittingUserEmail",
  "submittingUserOrg",
];

export const arrayWithTypeAndAge = z
  .array(
    z.object({
      type: z.string(),
      year: z.number().gt(-1).optional(),
    }),
  )
  .nullable();

const overviewValidation = toFormikValidationSchema(
  z.object({
    propertyOverviewDescription: z.string(),
    "status-KaiizenVerified": z.number(),
    "status-ZenEligible": z.number(),
    "status-Portfolio": z.string(),
    soldAs: z.string(),
  }),
);

const financialFields = [
  "askingPriceDollars",
  "feesKaiizenFeeDollars",
  "hoa-HasHoa",
  "hoa-FeeCadence",
  "occupancy-IsOccupied",
  "occupancy-MonthlyRentDollars",
  "occupancy-LeaseEndDate",
  "valuation-CherreAvmDollars",
  "valuation-KaiizenAsIsValueDollars",
  "valuation-KaiizenEstimatedArvDollars",
];

const financialsValidation = toFormikValidationSchema(
  z.object({
    // boolean in the db, number in the ui
    "hoa-HasHoa": z.number(),
    // boolean in the db, number in the ui
    "occupancy-IsOccupied": z.number(),
    // "valuation-KaiizenAsIsValueDollars": z.number(),
    askingPriceDollars: z.number(),
    feesKaiizenFeeDollars: z.number(),
    "valuation-CherreAvmDollars": z.number(),
    "valuation-KaiizenAsIsValueDollars": z.number(),
    "valuation-KaiizenEstimatedArvDollars": z.number(),
  }),
);

const geoFields = [
  "loc-houseNumber",
  "loc-street",
  "loc-city",
  "loc-stateCode",
  "loc-zip",
  "loc-regionMsaCode",
  "loc-county",
  "floodZoneType",
  "school-DistrictName",
  "school-ElementarySchoolScore",
  "school-MiddleSchoolScore",
  "school-HighSchoolScore",
  "proximity-ToHighPowerLinesMiles",
  "proximity-ToArterialRoadwaysMiles",
  "proximity-ToRailwaysMiles",
  "proximity-ToCommercialOrIndustrialAreasMiles",
];

const geoValidation = toFormikValidationSchema(
  z.object({
    floodZoneType: z.string(),
    "school-DistrictName": z.string(),
    "school-ElementarySchoolScore": z.number(),
    "school-MiddleSchoolScore": z.number(),
    "school-HighSchoolScore": z.number(),
    "proximity-ToHighPowerLinesMiles": z.number(),
    "proximity-ToArterialRoadwaysMiles": z.number(),
    "proximity-ToRailwaysMiles": z.number(),
    "proximity-ToCommercialOrIndustrialAreasMiles": z.number(),
  }),
);

const systemsConditionFields = [
  "electrical-AssessmentNotes",
  "electrical-RenoDaysLow",
  "electrical-RenoDaysHigh",
  "electrical-RenoLow",
  "electrical-RenoHigh",
  "hvac-AssessmentNotes",
  "hvac-RenoDaysLow",
  "hvac-RenoDaysHigh",
  "hvac-RenoLow",
  "hvac-RenoHigh",
  "plumbing-AssessmentNotes",
  "plumbing-RenoDaysLow",
  "plumbing-RenoDaysHigh",
  "plumbing-RenoLow",
  "plumbing-RenoHigh",
];

const systemsConditionValidation = toFormikValidationSchema(
  z.object({
    "electrical-RenoDaysHigh": z.number(),
    "electrical-RenoDaysLow": z.number(),
    "electrical-RenoHigh": z.number(),
    "electrical-RenoLow": z.number(),
    "hvac-RenoDaysHigh": z.number(),
    "hvac-RenoDaysLow": z.number(),
    "hvac-RenoHigh": z.number(),
    "hvac-RenoLow": z.number(),
    "plumbing-RenoDaysHigh": z.number(),
    "plumbing-RenoDaysLow": z.number(),
    "plumbing-RenoHigh": z.number(),
    "plumbing-RenoLow": z.number(),
  }),
);

const systemsDetailFields = [
  "hvac-SystemType",
  "hvac-Year",
  "electrical-SystemType",
  "electrical-Amperage",
  "plumbing-WaterSupplyType",
  "plumbing-SupplyPipeMaterial",
  "plumbing-DrainPipeMaterial",
  "plumbing-WaterHeaterType",
  "plumbing-WaterHeaterYear",
  "plumbing-AssessmentNotes",
  "utility-ElectricProvider",
  "utility-GasProvider",
  "utility-WaterSource",
  "utility-SewerType",
];

const systemsDetailValidation = toFormikValidationSchema(
  z.object({
    "electrical-SystemType": z.array(z.string()),
    "hvac-SystemType": z.array(z.string()),
    "plumbing-DrainPipeMaterial": z.array(z.string()),
    "plumbing-SupplyPipeMaterial": z.array(z.string()),
    "plumbing-WaterHeaterType": z.string(),
    "plumbing-WaterHeaterYear": z.number(),
    "plumbing-WaterSupplyType": z.string(),
    "utility-ElectricProvider": z.string(),
    "utility-GasProvider": z.string(),
    // "utility-SewerProvider": z.string(),
    "utility-SewerType": z.string(),
    "utility-WaterSource": z.string(),
  }),
);

const interiorConditionFields = [
  "basement-AssessmentNotes",
  "basement-RenoDaysLow",
  "basement-RenoDaysHigh",
  "basement-RenoLow",
  "basement-RenoHigh",
  "bathrooms-AssessmentNotes",
  "bathrooms-RenoDaysLow",
  "bathrooms-RenoDaysHigh",
  "bathrooms-RenoLow",
  "bathrooms-RenoHigh",
  "bedrooms-AssessmentNotes",
  "bedrooms-RenoDaysLow",
  "bedrooms-RenoDaysHigh",
  "bedrooms-RenoLow",
  "bedrooms-RenoHigh",
  "commonAreas-AssessmentNotes",
  "commonAreas-RenoDaysLow",
  "commonAreas-RenoDaysHigh",
  "commonAreas-RenoLow",
  "commonAreas-RenoHigh",
  "diningRoom-AssessmentNotes",
  "diningRoom-RenoDaysLow",
  "diningRoom-RenoDaysHigh",
  "diningRoom-RenoLow",
  "diningRoom-RenoHigh",
  "familyRoom-AssessmentNotes",
  "familyRoom-RenoDaysLow",
  "familyRoom-RenoDaysHigh",
  "familyRoom-RenoLow",
  "familyRoom-RenoHigh",
  "kitchen-AssessmentNotes",
  "kitchen-RenoDaysLow",
  "kitchen-RenoDaysHigh",
  "kitchen-RenoLow",
  "kitchen-RenoHigh",
  "laundryRoom-AssessmentNotes",
  "laundryRoom-RenoDaysLow",
  "laundryRoom-RenoDaysHigh",
  "laundryRoom-RenoLow",
  "laundryRoom-RenoHigh",
  "livingRoom-AssessmentNotes",
  "livingRoom-RenoDaysLow",
  "livingRoom-RenoDaysHigh",
  "livingRoom-RenoLow",
  "livingRoom-RenoHigh",
];

const interiorConditionValidation = toFormikValidationSchema(
  z.object({
    "basement-RenoDaysHigh": z.number(),
    "basement-RenoDaysLow": z.number(),
    "basement-RenoHigh": z.number(),
    "basement-RenoLow": z.number(),
    "bathrooms-RenoDaysHigh": z.number(),
    "bathrooms-RenoDaysLow": z.number(),
    "bathrooms-RenoHigh": z.number(),
    "bathrooms-RenoLow": z.number(),
    "bedrooms-RenoDaysHigh": z.number(),
    "bedrooms-RenoDaysLow": z.number(),
    "bedrooms-RenoHigh": z.number(),
    "bedrooms-RenoLow": z.number(),
    "commonAreas-RenoDaysHigh": z.number(),
    "commonAreas-RenoDaysLow": z.number(),
    "commonAreas-RenoHigh": z.number(),
    "commonAreas-RenoLow": z.number(),
    "diningRoom-RenoDaysHigh": z.number(),
    "diningRoom-RenoDaysLow": z.number(),
    "diningRoom-RenoHigh": z.number(),
    "diningRoom-RenoLow": z.number(),
    "familyRoom-RenoDaysHigh": z.number(),
    "familyRoom-RenoDaysLow": z.number(),
    "familyRoom-RenoHigh": z.number(),
    "familyRoom-RenoLow": z.number(),
    "kitchen-RenoDaysHigh": z.number(),
    "kitchen-RenoDaysLow": z.number(),
    "kitchen-RenoHigh": z.number(),
    "kitchen-RenoLow": z.number(),
    "laundryRoom-RenoDaysHigh": z.number(),
    "laundryRoom-RenoDaysLow": z.number(),
    "laundryRoom-RenoHigh": z.number(),
    "laundryRoom-RenoLow": z.number(),
    "livingRoom-RenoDaysHigh": z.number(),
    "livingRoom-RenoDaysLow": z.number(),
    "livingRoom-RenoHigh": z.number(),
    "livingRoom-RenoLow": z.number(),
  }),
);

const interiorDetailFields = [
  "basement-Type",
  "bathrooms-Count",
  "bedrooms-Count",
  "kitchen-Appliances",
  "laundryRoom-Appliances",
];
const interiorDetailValidation = toFormikValidationSchema(
  z.object({
    "basement-Type": z.string(),
    "bathrooms-Count": z.string(),
    "bedrooms-Count": z.number(),
    "kitchen-Appliances": arrayWithTypeAndAge,
    "laundryRoom-Appliances": arrayWithTypeAndAge,
  }),
);

const constructionConditionFields = [
  "addition-RenoDaysLow",
  "addition-RenoDaysHigh",
  "addition-RenoLow",
  "addition-RenoHigh",
  "addition-AssessmentNotes",
  "attic-RenoDaysLow",
  "attic-RenoDaysHigh",
  "attic-RenoLow",
  "attic-RenoHigh",
  "attic-AssessmentNotes",
  "crawlspace-RenoDaysLow",
  "crawlspace-RenoDaysHigh",
  "crawlspace-RenoLow",
  "crawlspace-RenoHigh",
  "crawlspace-AssessmentNotes",
  "facade-RenoDaysLow",
  "facade-RenoDaysHigh",
  "facade-RenoLow",
  "facade-RenoHigh",
  "facade-AssessmentNotes",
  "fireplaceAndChimney-RenoDaysLow",
  "fireplaceAndChimney-RenoDaysHigh",
  "fireplaceAndChimney-RenoLow",
  "fireplaceAndChimney-RenoHigh",
  "fireplaceAndChimney-AssessmentNotes",
  "foundation-RenoDaysLow",
  "foundation-RenoDaysHigh",
  "foundation-RenoLow",
  "foundation-RenoHigh",
  "foundation-AssessmentNotes",
  "parking-RenoDaysLow",
  "parking-RenoDaysHigh",
  "parking-RenoLow",
  "parking-RenoHigh",
  "parking-AssessmentNotes",
  "roof-RenoDaysLow",
  "roof-RenoDaysHigh",
  "roof-RenoLow",
  "roof-RenoHigh",
  "roof-AssessmentNotes",
  "solar-RenoDaysLow",
  "solar-RenoDaysHigh",
  "solar-RenoLow",
  "solar-RenoHigh",
  "solar-AssessmentNotes",
];

const constructionConditionValidation = toFormikValidationSchema(
  z.object({
    "addition-RenoDaysHigh": z.number(),
    "addition-RenoDaysLow": z.number(),
    "addition-RenoHigh": z.number(),
    "addition-RenoLow": z.number(),
    "attic-RenoDaysHigh": z.number(),
    "attic-RenoDaysLow": z.number(),
    "attic-RenoHigh": z.number(),
    "attic-RenoLow": z.number(),
    "crawlspace-RenoDaysHigh": z.number(),
    "crawlspace-RenoDaysLow": z.number(),
    "crawlspace-RenoHigh": z.number(),
    "crawlspace-RenoLow": z.number(),
    "facade-RenoDaysHigh": z.number(),
    "facade-RenoDaysLow": z.number(),
    "facade-RenoHigh": z.number(),
    "facade-RenoLow": z.number(),
    "fireplaceAndChimney-RenoDaysHigh": z.number(),
    "fireplaceAndChimney-RenoDaysLow": z.number(),
    "fireplaceAndChimney-RenoHigh": z.number(),
    "fireplaceAndChimney-RenoLow": z.number(),
    "foundation-RenoDaysHigh": z.number(),
    "foundation-RenoDaysLow": z.number(),
    "foundation-RenoHigh": z.number(),
    "foundation-RenoLow": z.number(),
    "parking-RenoDaysHigh": z.number(),
    "parking-RenoDaysLow": z.number(),
    "parking-RenoHigh": z.number(),
    "parking-RenoLow": z.number(),
    "roof-RenoDaysHigh": z.number(),
    "roof-RenoDaysLow": z.number(),
    "roof-RenoHigh": z.number(),
    "roof-RenoLow": z.number(),
    "solar-RenoDaysHigh": z.number(),
    "solar-RenoDaysLow": z.number(),
    "solar-RenoHigh": z.number(),
    "solar-RenoLow": z.number(),
  }),
);

const constructionDetailFields = [
  "yearBuilt",
  "interiorSqft",
  "roof-Material",
  "roof-YearInstalled",
  "parking-GarageType",
  "parking-Spots",
  "facade-Type",
  "foundation-Type",
  "foundation-YearBuilt",
  "solar-Exists",
  "addition-Type",
  //"addition-YearBuilt",
];

const constructionDetailValidation = toFormikValidationSchema(
  z.object({
    "addition-Type": arrayWithTypeAndAge,
    "facade-Type": z.array(z.string()),
    "foundation-Type": arrayWithTypeAndAge,
    interiorSqft: z.number(),
    "parking-GarageType": z.array(z.string()),
    "parking-Spots": z.number(),
    "roof-Material": arrayWithTypeAndAge,
    "solar-Exists": z.number(),
    yearBuilt: z.number(),
  }),
);

const propertyConditionFields = [
  "additionalStructures-AssessmentNotes",
  "additionalStructures-RenoDaysLow",
  "additionalStructures-RenoDaysHigh",
  "additionalStructures-RenoLow",
  "additionalStructures-RenoHigh",
  "driveway-AssessmentNotes",
  "driveway-RenoDaysLow",
  "driveway-RenoDaysHigh",
  "driveway-RenoLow",
  "driveway-RenoHigh",
  "fencing-AssessmentNotes",
  "fencing-RenoDaysLow",
  "fencing-RenoDaysHigh",
  "fencing-RenoLow",
  "fencing-RenoHigh",
  "lot-AssessmentNotes",
  "lot-RenoDaysLow",
  "lot-RenoDaysHigh",
  "lot-RenoLow",
  "lot-RenoHigh",
  "pool-AssessmentNotes",
  "pool-RenoDaysLow",
  "pool-RenoDaysHigh",
  "pool-RenoLow",
  "pool-RenoHigh",
];

const propertyConditionValidation = toFormikValidationSchema(
  z.object({
    "additionalStructures-RenoDaysHigh": z.number(),
    "additionalStructures-RenoDaysLow": z.number(),
    "additionalStructures-RenoHigh": z.number(),
    "additionalStructures-RenoLow": z.number(),
    "driveway-RenoDaysHigh": z.number(),
    "driveway-RenoDaysLow": z.number(),
    "driveway-RenoHigh": z.number(),
    "driveway-RenoLow": z.number(),
    "fencing-RenoDaysHigh": z.number(),
    "fencing-RenoDaysLow": z.number(),
    "fencing-RenoHigh": z.number(),
    "fencing-RenoLow": z.number(),
    "lot-RenoDaysHigh": z.number(),
    "lot-RenoDaysLow": z.number(),
    "lot-RenoHigh": z.number(),
    "lot-RenoLow": z.number(),
    "pool-RenoDaysHigh": z.number(),
    "pool-RenoDaysLow": z.number(),
    "pool-RenoHigh": z.number(),
    "pool-RenoLow": z.number(),
  }),
);

const propertyDetailFields = [
  "lotSizeSqft",
  "fencing-Type",
  "driveway-Type",
  "pool-Type",
  "additionalStructures",
];

const propertyDetailValidation = toFormikValidationSchema(
  z.object({
    additionalStructures: arrayWithTypeAndAge,
    "driveway-Type": z
      .array(
        z.object({
          included: z.boolean(),
          type: z.string(),
          parkingSpots: z.number().gt(-1),
        }),
      )
      .nullable(),
    "fencing-Type": z.array(z.string()),
    lotSizeSqft: z.number(),
    "pool-Type": z.array(z.string()),
  }),
);

const miscConditionFields = [
  "permits-AssessmentNotes",
  "permits-RenoDaysLow",
  "permits-RenoDaysHigh",
  "permits-RenoLow",
  "permits-RenoHigh",
  "environmental-AssessmentNotes",
  "environmental-RenoDaysLow",
  "environmental-RenoDaysHigh",
  "environmental-RenoLow",
  "environmental-RenoHigh",
  "zoning-AssessmentNotes",
  "zoning-RenoDaysLow",
  "zoning-RenoDaysHigh",
  "zoning-RenoLow",
  "zoning-RenoHigh",
];

const miscConditionValidation = toFormikValidationSchema(
  z.object({
    "environmental-RenoDaysHigh": z.number(),
    "environmental-RenoDaysLow": z.number(),
    "environmental-RenoHigh": z.number(),
    "environmental-RenoLow": z.number(),
    "permits-RenoDaysHigh": z.number(),
    "permits-RenoDaysLow": z.number(),
    "permits-RenoHigh": z.number(),
    "permits-RenoLow": z.number(),
    "zoning-RenoDaysHigh": z.number(),
    "zoning-RenoDaysLow": z.number(),
    "zoning-RenoHigh": z.number(),
    "zoning-RenoLow": z.number(),
  }),
);

const miscFields = ["kaiizenInternalNotes", "environmental-Issues"];

const miscValidation = toFormikValidationSchema(
  z.object({
    kaiizenInternalNotes: z.string(),
    "environmental-Issues": z.array(z.string()),
  }),
);

const boolToNumber = (value: string | boolean) => {
  if (value === "") {
    return value;
  }

  if (value) {
    return 1;
  }

  return 0;
};

// the ui presents age as a number of years, in the database it's stored as A Year
export const yearsFromYear = (storedYear: number | "" | null) => {
  if (!storedYear) {
    return storedYear;
  }

  return new Date().getUTCFullYear() - storedYear;
};

// use either the first or second array value, or return 0
const parseStoredRenoValue = (type: "low" | "high", initVal?: number[]) => {
  if (initVal) {
    return type === "low" ? initVal[0] : initVal[1];
  }

  return 0;
};

interface detailWithYearAndBool {
  included: boolean;
  type: string;
  year?: number | null;
  parkingSpots?: number;
}

interface detailWithYear {
  type: string;
  year?: number | null;
  parkingSpots?: number;
}

//
const populateDetailsWithYears = (
  serverVals: detailWithYear[],
  contextVals: detailWithYearAndBool[],
) => {
  let index;

  if (Array.isArray(serverVals)) {
    serverVals.forEach((sVal) => {
      index = contextVals.findIndex((cVal) => cVal.type === sVal.type);
      if (index > -1) {
        contextVals[index] = {
          type: sVal.type,
          included: true,
        };

        if (sVal.year !== undefined) {
          contextVals[index]["year"] = yearsFromYear(sVal.year) || 0;
        } else if (sVal.parkingSpots !== undefined) {
          contextVals[index]["parkingSpots"] = sVal.parkingSpots;
        }
      }
    });
  }

  return contextVals;
};

const populateInitValues = (
  screenName: PropertyDetailScreen,
  section: propertyEditSection,
  keys: string[],
  initObj: any,
) => {
  const vals: any = {};

  keys.forEach((initField: string) => {
    if (section === "condition") {
      // reno estimates need to be split into two number fields
      if (initField.endsWith("RenoLow")) {
        vals[initField] = parseStoredRenoValue(
          "low",
          initObj[`${initField.split("-")[0]}RenoEstimateRangeDollars`],
        );
      } else if (initField.endsWith("RenoHigh")) {
        vals[initField] = parseStoredRenoValue(
          "high",
          initObj[`${initField.split("-")[0]}RenoEstimateRangeDollars`],
        );
      } else if (initField.endsWith("RenoDaysLow")) {
        vals[initField] = parseStoredRenoValue(
          "low",
          initObj[`${initField.split("-")[0]}RenoEstimateRangeDays`],
        );
      } else if (initField.endsWith("RenoDaysHigh")) {
        vals[initField] = parseStoredRenoValue(
          "high",
          initObj[`${initField.split("-")[0]}RenoEstimateRangeDays`],
        );
      } else {
        vals[initField] = initObj[initField.replace("-", "")] ?? "";
      }
    } else {
      // internally most fields are named something like foo-Bar
      // in the database, they're stored as fooBar
      vals[initField] = initObj[initField.replace("-", "")] ?? "";
    }
  });

  // manually map non-standard fields
  if (section === "detail") {
    if (screenName === "geographic") {
      vals["loc-city"] = initObj.city;
      vals["loc-houseNumber"] = initObj.houseNumber;
      vals["loc-stateCode"] = initObj.stateCode;
      vals["loc-street"] = initObj.street;
      vals["loc-zip"] = initObj.zip;
      vals["loc-county"] = initObj["_regionDeterminations"]?.county.name ?? "";
      vals["loc-msaName"] = initObj.msaName ?? "";
      vals["loc-countyName"] = initObj.countyName ?? "";
    }

    if (screenName === "financials") {
      vals["cherreAVM"] = propPriceStr(initObj.cherreAVM) ?? "";
      vals["hoa-HasHoa"] = boolToNumber(initObj.hoaHasHoa);
      vals["occupancy-IsOccupied"] = boolToNumber(initObj.occupancyIsOccupied);
      // these aren't stored in the property, but the ui uses
      // them to enable 'make this a listing' functionality
      vals["strategy-offerPriceLow"] = null;
      vals["strategy-offerPriceHigh"] = null;
    }

    if (screenName === "property") {
      const additionalStructuresBase = [
        { type: "deck", year: 0, included: false },
        { type: "gazebo", year: 0, included: false },
        { type: "greenhouse", year: 0, included: false },
        { type: "screened porch", year: 0, included: false },
        { type: "shed", year: 0, included: false },
        { type: "workshop", year: 0, included: false },
        { type: "other", year: 0, included: false },
      ];

      vals["additionalStructures"] = [
        ...populateDetailsWithYears(
          vals["additionalStructures"],
          additionalStructuresBase,
        ),
      ];
    }

    if (screenName === "interior") {
      const kitchenBase = [
        { type: "cook top", year: 0, included: false },
        { type: "dishwasher", year: 0, included: false },
        { type: "double wall oven", year: 0, included: false },
        { type: "freezer", year: 0, included: false },
        { type: "garbage disposal", year: 0, included: false },
        { type: "microwave", year: 0, included: false },
        { type: "range/oven", year: 0, included: false },
        { type: "refrigerator", year: 0, included: false },
        { type: "other", year: 0, included: false },
      ];

      const laundryBase = [
        { type: "dryer", year: 0, included: false },
        { type: "washing machine", year: 0, included: false },
        { type: "other", year: 0, included: false },
      ];

      vals["kitchen-Appliances"] = [
        ...populateDetailsWithYears(vals["kitchen-Appliances"], kitchenBase),
      ];

      vals["laundryRoom-Appliances"] = [
        ...populateDetailsWithYears(
          vals["laundryRoom-Appliances"],
          laundryBase,
        ),
      ];
    }

    if (screenName === "overview") {
      // force boolean fields to numbers in the ui
      vals["status-KaiizenVerified"] = boolToNumber(
        initObj.statusKaiizenVerified,
      );
      vals["status-ZenEligible"] = boolToNumber(initObj.statusZenEligible);
    }

    if (screenName === "systems") {
      vals["hvac-Year"] = yearsFromYear(initObj.hvacYear);
      vals["plumbing-WaterHeaterYear"] = yearsFromYear(
        initObj.plumbingWaterHeaterYear,
      );
    }

    if (screenName === "construction") {
      const roofBase = [
        { type: "aluminum", year: 0, included: false },
        { type: "asphalt shingle", year: 0, included: false },
        { type: "cedar shakes", year: 0, included: false },
        { type: "clay", year: 0, included: false },
        { type: "concrete tile", year: 0, included: false },
        { type: "copper", year: 0, included: false },
        { type: "galvanized steel", year: 0, included: false },
        { type: "slate tile", year: 0, included: false },
        { type: "other", year: 0, included: false },
      ];

      const garageBase = [
        { type: "attached garage", parkingSpots: 0, included: false },
        { type: "carport", parkingSpots: 0, included: false },
        { type: "detached garage", parkingSpots: 0, included: false },
        { type: "other", parkingSpots: 0, included: false },
      ];

      const foundationBase = [
        { type: "concrete slab", year: 0, included: false },
        { type: "concrete masonry units", year: 0, included: false },
        { type: "crawl space stone", year: 0, included: false },
        { type: "other", year: 0, included: false },
      ];

      const additionBase = [
        { type: "attic/basement conversion", year: 0, included: false },
        { type: "garage conversion", year: 0, included: false },
        { type: "room addition", year: 0, included: false },
        { type: "second story", year: 0, included: false },
        { type: "sun room", year: 0, included: false },
        { type: "tiny house addition", year: 0, included: false },
        { type: "other", year: 0, included: false },
      ];

      vals["solar-Exists"] = boolToNumber(initObj.solarExists);
      vals["roof-Material"] = [
        ...populateDetailsWithYears(vals["roof-Material"], roofBase),
      ];
      vals["foundation-Type"] = [
        ...populateDetailsWithYears(vals["foundation-Type"], foundationBase),
      ];
      vals["addition-Type"] = [
        ...populateDetailsWithYears(vals["addition-Type"], additionBase),
      ];
      vals["parking-GarageType"] = [
        ...populateDetailsWithYears(vals["parking-GarageType"], garageBase),
      ];

      const parking = vals["parking-GarageType"];
      if (parking && !Array.isArray(parking)) {
        vals["parking-GarageType"] = [parking];
      }
    }
  }

  return vals;
};

const conditionScreenMap = {
  construction: constructionConditionFields,
  interior: interiorConditionFields,
  miscellaneous: miscConditionFields,
  property: propertyConditionFields,
  systems: systemsConditionFields,
};

const conditionSchemaMap = {
  construction: constructionConditionValidation,
  interior: interiorConditionValidation,
  miscellaneous: miscConditionValidation,
  property: propertyConditionValidation,
  systems: systemsConditionValidation,
};

const detailScreenMap = {
  construction: constructionDetailFields,
  geographic: geoFields,
  financials: financialFields,
  interior: interiorDetailFields,
  miscellaneous: miscFields,
  overview: overviewFields,
  property: propertyDetailFields,
  systems: systemsDetailFields,
};

const detailSchemaMap = {
  construction: constructionDetailValidation,
  financials: financialsValidation,
  geographic: geoValidation,
  interior: interiorDetailValidation,
  miscellaneous: miscValidation,
  overview: overviewValidation,
  property: propertyDetailValidation,
  systems: systemsDetailValidation,
};

const getFormikConfig =
  (section: propertyEditSection, screenMap: any, schemaMap?: any) =>
  (screenName: PropertyDetailScreen, initObj: any) => ({
    initialValues: populateInitValues(
      screenName,
      section,
      screenMap[screenName],
      initObj,
    ),
    // this is required by Formik, but we use an alternate save method defined in the context
    onSubmit: () => {},
    validateOnMount: true,
    validationSchema: schemaMap ? schemaMap[screenName] : undefined,
  });

export const getDetailsFormikConfig = getFormikConfig(
  "detail",
  detailScreenMap,
  detailSchemaMap,
);

export const getConditionFormikConfig = getFormikConfig(
  "condition",
  conditionScreenMap,
  conditionSchemaMap,
);
