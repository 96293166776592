import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { materialTheme } from "./materialTheme";

export const emotionCache = createCache({ key: "css" });

export const MaterialUiProvider = ({ children }: { children: any }) => {
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>
    </CacheProvider>
  );
};
