import { css } from "@emotion/react";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import { useRef, useState } from "react";
import { useUser } from "~/react/ServerStateProvider";
import { DownloadIcon } from "~/assets/icons";
import colors from "~/react/utilties/colors";

const styles = css`
  gap: 10px;
  display: flex;
  justify-content: end;

  button {
    align-items: center;
    border-radius: 40px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    justify-content: center;
    width: 40px;

    &.user {
      background-color: ${colors["blue-primary"]};
      border: none;
      color: ${colors.white};
    }
    &.download {
      background: none;
      border: solid 2.5px ${colors.purple};

      &:hover {
        border-color: white;
        svg {
          fill: white;
        }
      }
    }
  }
`;

export const SectionNavUserRow = ({
  onExportClick,
}: {
  onExportClick?: () => string;
}) => {
  const user = useUser();
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
  const [downloadMenuIsOpen, setDownloadMenuIsOpen] = useState(false);
  const userMenuBtn = useRef<HTMLButtonElement>(null);
  const downloadMenuBtn = useRef<HTMLButtonElement>(null);
  const doLogout = () => {
    window.alert("Not yet implemented");
  };

  let csvLink = "";

  if (typeof window === "object") {
    const path = onExportClick ? onExportClick() : "/";

    if (window.location.host.includes("localhost")) {
      csvLink = `//fusion.kaiizen.localhost:3000${path}`;
    } else if (window.location.host.includes("staging")) {
      csvLink = `//fusion.staging.kaiizen.com${path}`;
    } else {
      csvLink = `//fusion.kaiizen.com${path}`;
    }
  }

  return (
    <div css={styles}>
      {onExportClick ? (
        <>
          <button
            className="download"
            ref={downloadMenuBtn}
            onClick={() => {
              setDownloadMenuIsOpen(!downloadMenuIsOpen);
            }}
          >
            <DownloadIcon />
          </button>
          <Popover
            open={downloadMenuIsOpen}
            onClose={() => setDownloadMenuIsOpen(false)}
            anchorEl={downloadMenuBtn.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "center",
            }}
          >
            {/*<List>*/}
            {/*  <ListItem>*/}
            {/*    <ListItemText*/}
            {/*      primary={user.name}*/}
            {/*      primaryTypographyProps={{ style: { fontWeight: "bold" } }}*/}
            {/*    />*/}
            {/*  </ListItem>*/}
            {/*</List>*/}
            {/*<Divider />*/}
            <List>
              <ListItem component="a" href={csvLink}>
                {/*<ListItemButton onClick={onExportClick}>*/}
                <ListItemText primary="CSV" />
                {/*</ListItemButton>*/}
              </ListItem>
            </List>
          </Popover>
        </>
      ) : null}
      {user ? (
        <>
          <button
            className="user"
            ref={userMenuBtn}
            onClick={() => setUserMenuIsOpen(!userMenuIsOpen)}
            style={{}}
          >
            {/* Show the user's initials */}
            {user.name
              .split(" ")
              .map((w) => w.substring(0, 1))
              .join("")}
          </button>
          <Popover
            open={userMenuIsOpen}
            onClose={() => setUserMenuIsOpen(false)}
            anchorEl={userMenuBtn.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "right",
            }}
          >
            <List>
              <ListItem>
                <ListItemText
                  primary={user.name}
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={doLogout}>
                  <ListItemText primary="Log out" />
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
        </>
      ) : null}
    </div>
  );
};
