import { useContext, createContext } from "react";

// TODO: move to shared types (or something - TBD)
import { AuthenticatedAdminUser } from "~/admin/types";
import { AuthenticatedAppUser } from "~/clients/zen";

export type ServerStatePayload = {
  user: AuthenticatedAdminUser | AuthenticatedAppUser | null;
  viewState: any;
  version: string;
};
const ServerStateContext = createContext<ServerStatePayload | null>(null);

const useServerState = () => {
  const context = useContext(ServerStateContext);
  if (!context) {
    throw new Error("useServerState must be used within a ServerStateProvider");
  }
  return context;
};

export const ServerStateProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: ServerStatePayload;
}) => (
  <ServerStateContext.Provider value={value}>
    {children}
  </ServerStateContext.Provider>
);

export const useViewState = <T extends (...args: any) => any>(): Awaited<
  ReturnType<T>
> => useServerState().viewState;

export const useViewStateDirect = <T extends Record<string, any>>(): T =>
  useServerState().viewState;

export const useUser = () => useServerState().user || null;
