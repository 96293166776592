import { EffectCallback, useEffect, useState } from "react";

type Props = {
  children: React.ReactNode;
};
export function ClientOnly({ children }: Props) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  return mounted ? <>{children}</> : null;
}

/**
 * A hook that runs an effect once, only on mount. This is useful for logic that
 * should only run on the browser, not during server-side render.
 */
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (fn: EffectCallback) => useEffect(fn, []);
