import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

/**
 * Get whether or not the side nav is expanded or collapsed. This stores the
 * current preference in a persistent cookie, which allows the preference to be
 * respected for server-side rendering.
 */
export function useSitenavState() {
  const [cookies, setCookie] = useCookies<
    "ui_sitenav_state",
    { ui_sitenav_state: "expanded" | "collapsed" }
  >(["ui_sitenav_state"]);
  const [state, setState] = useState<"expanded" | "collapsed">(
    cookies.ui_sitenav_state ?? "collapsed",
  );

  useEffect(() => {
    setCookie("ui_sitenav_state", state, {
      path: "/",
      sameSite: "lax",
      maxAge: 60 * 60 * 24 * 365, // one year
    });
  }, [state, setCookie]);

  return [state, setState] as const;
}
