import { PropertySnapshot } from "~/lib/models/PropertySnapshot";
import {
  formatDetailAge,
  formatRenoArray,
  PropertyAccordion,
} from "~/clients/zen/partials";
import {
  accordionPhotoState,
  DetailScreen,
  filterDetailPhotos,
} from "~/clients/zen/partials/property/OverviewDetail";
import { PropertyDetailPhoto } from "~/app/pages/opportunities/types";
import {
  detailArrayAsString,
  detailIsIncluded,
} from "~/clients/zen/pages/ZenPropertyDetail";
import { PhotoNavigator } from "~/react/components/property/photo-navigator/PhotoNavigator";
import { useState } from "react";

export const SystemsDetail = ({
  property,
  photos,
  renoTotal,
}: {
  property: PropertySnapshot;
  photos: PropertyDetailPhoto[];
  renoTotal: number[];
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  const { state: propVals } = property;
  const filteredPhotos = filterDetailPhotos(photos, "systems");
  const getSystemsPhotoState = accordionPhotoState(
    filteredPhotos,
    setPhotoIndex,
  );

  return (
    <DetailScreen className="container">
      <h4>Systems</h4>
      <div className="wrap">
        <div className="left">
          {detailIsIncluded("hvac", propVals) && (
            <PropertyAccordion
              title="HVAC"
              titleValue={detailArrayAsString(propVals.hvacSystemType)}
              photoState={getSystemsPhotoState("systems/hvac")}
              expandedDefault={true}
              rows={[
                {
                  name: "HVAC System Type",
                  value: detailArrayAsString(propVals.hvacSystemType),
                },
                {
                  name: "Age",
                  value: propVals.hvacYear,
                  formatFunc: formatDetailAge,
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(propVals.hvacRenoEstimateRangeDollars),
                },
              ]}
            />
          )}
          {detailIsIncluded("electrical", propVals) && (
            <PropertyAccordion
              title="Electrical"
              titleValue={detailArrayAsString(propVals.electricalSystemType)}
              photoState={getSystemsPhotoState("systems/electrical")}
              rows={[
                {
                  name: "Electrical System Type",
                  value: detailArrayAsString(propVals.electricalSystemType),
                },
                {
                  name: "Amps",
                  value: propVals.electricalAmperage,
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.electricalRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("plumbing", propVals) && (
            <PropertyAccordion
              title="Plumbing"
              photoState={getSystemsPhotoState("systems/plumbing")}
              rows={[
                {
                  name: "Water Supply Type",
                  value: propVals.plumbingWaterSupplyType,
                },
                {
                  name: "Supply Pipe Material",
                  value: detailArrayAsString(
                    propVals.plumbingSupplyPipeMaterial,
                  ),
                },
                {
                  name: "Drain Pipe Material",
                  value: detailArrayAsString(
                    propVals.plumbingDrainPipeMaterial,
                  ),
                },
                {
                  name: "Water Heater Type",
                  value: propVals.plumbingWaterHeaterType,
                },
                {
                  name: "Water Heater Age",
                  value: propVals.plumbingWaterHeaterYear,
                  formatFunc: formatDetailAge,
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.plumbingRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("utilities", propVals) && (
            <PropertyAccordion
              title="Utilities"
              photoState={getSystemsPhotoState("systems/utilities")}
              rows={[
                {
                  name: "Electric",
                  value: propVals.utilityElectricProvider,
                },
                {
                  name: "Gas",
                  value: propVals.utilityGasProvider,
                },
                {
                  name: "Water",
                  value: propVals.utilityWaterSource,
                },
                {
                  name: "Sewer",
                  value: propVals.utilitySewerType,
                },
              ]}
            />
          )}
          <PropertyAccordion
            title="Total Systems Reno"
            titleValue={formatRenoArray(renoTotal)}
            rows={[]}
          />
        </div>
        <div className="right">
          <PhotoNavigator
            photos={photos}
            currentIndex={photoIndex}
            onPhotoChangeClick={setPhotoIndex}
            photoBackgroundSize={"contain"}
            paginationAlign={"right"}
            filteredPhotos={filteredPhotos}
            category={"systems"}
            onSeeAllClick={() => {
              window.location.assign(`/property/${property.propertyId}/photos`);
            }}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
