/**
 * Given a number of acres, return a string like "0.5 ac" or "0.23 ac".
 */
export const acresStr = (acres: number | undefined) => {
  if (acres == undefined) {
    return "--";
  }
  if (acres > 0.3) {
    return `${acres.toFixed(1)} Acres`;
  }
  return `${acres.toFixed(2)} Acres`;
};

/**
 * Given a date string, return a string like "5/13/2024".
 */
export const dateStr = (date: string | undefined) => {
  if (date == undefined) {
    return "--";
  }
  return new Date(date).toLocaleDateString();
};

/**
 * Given a property price in the hundreds of thousands, return a string like
 * "$233,000" or "$120,995".
 */
export const propPriceStr = (price: number | undefined | null) => {
  if (!price) {
    return "--";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(price);
};

/**
 * Given a price in the hundreds of thousands, return a string like "$233k" or
 * "$120k".
 */
export const thouPriceStr = (price: number | undefined) => {
  if (price == undefined) {
    return "--";
  }
  const rounded = Math.round(price / 1000);
  return `$${rounded}K`;
};

/**
 * Given a number of square feet, return a string like "1,500 sqft" or "2,300
 * sqft".
 */
export const sqftStr = (sqft: number | undefined) => {
  if (sqft == undefined) {
    return "--";
  }
  return `${sqft.toLocaleString()} sqft`;
};

/**
 * Given a percentage between 0 and 1, return a string like "7.00%" or "3.51%".
 */
export const precisePctStr = (pct: number | undefined) => {
  if (pct == undefined) {
    return "--";
  }
  return `${(pct * 100).toFixed(2)}%`;
};

export const pctStr = (pct: number | undefined) => {
  if (pct == undefined) {
    return "--";
  }
  return `${Math.round(pct * 100)}%`;
};

/**
 * Given a string, returns that string with the first letter capitalized.
 */
export const ucFirst = (str: string) => {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};
