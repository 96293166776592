import { FormEventHandler, useState } from "react";
import { css } from "@emotion/react";
import { createKaiizenSsrPage } from "~/new/lib/kaiizenAppShared";

import { SiteNav } from "~/clients/sharedPartials";
import { Button } from "~/clients/sharedWidgets";
import {
  SectionContainer,
  SectionNavContainer,
  SiteContainer,
} from "../../sharedStyles";

import { zenApiClient } from "~/clients/zen/api";
import { sanitizeRedirectPath } from "~/lib/url";
import {
  FormContainer,
  FormField,
  TextInput,
} from "~/admin/components/AdminForm/AdminForm";

type LoginProps = {
  redirect: string | undefined;
};

const contStyles = css`
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  position: relative;
  top: 30px;
  width: 50%;
`;

export const labeledZenLoginWithEmailView = {
  ZenLoginWithEmailView: ({ redirect }: LoginProps) => {
    const [email, setEmail] = useState<string>("");
    const [sent, setSent] = useState<boolean>(false);

    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const onSubmit: FormEventHandler = async (e) => {
      e.preventDefault();
      await zenApiClient.sendLoginMagicLink.mutate({ email, redirect });
      setSent(true);
    };
    const differentEmail = () => {
      setEmail("");
      setSent(false);
    };

    return (
      <SiteContainer>
        <SiteNav site="zen" />
        <SectionContainer>
          <SectionNavContainer />
          <div css={contStyles} className="container">
            {sent ? (
              <>
                <p>
                  We&apos;ve sent an email to {email} with a magic login link.
                  Click the link in your email to complete the login process.
                </p>
                <Button color="white" onClick={differentEmail}>
                  &laquo; enter a different email
                </Button>
              </>
            ) : (
              <>
                <p>Enter your email address to log in:</p>
                <FormContainer>
                  <form method="post" action="" onSubmit={onSubmit}>
                    <FormField label="Email">
                      <TextInput
                        autoComplete="off"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormField>
                    <Button
                      type="submit"
                      color="purple"
                      disabled={!isValidEmail}
                    >
                      Log in
                    </Button>
                  </form>
                </FormContainer>
              </>
            )}
          </div>
        </SectionContainer>
      </SiteContainer>
    );
  },
};

export const zenLoginWithEmailSsrPage = () =>
  createKaiizenSsrPage({
    route: "/",
    load: ({ req }) =>
      typeof req.query.redirect === "string"
        ? { redirect: sanitizeRedirectPath(req.query.redirect) }
        : { redirect: undefined },
    labeledView: labeledZenLoginWithEmailView,
  });
