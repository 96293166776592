import { createKaiizenSsrPage } from "~/new/lib/kaiizenAppShared";
import {
  SectionContainer,
  SectionNavContainer,
  SiteContainer,
} from "../../sharedStyles";
import { SiteNav } from "~/clients/sharedPartials";
import { sampleOpportunities } from "../lib";

// REFACTOR TODO: modularize these dependencies
import { PropertySnapshot } from "~/lib/models/PropertySnapshot";
import { PropertyType } from "~/lib/uiTypes";

type LoaderResult = {
  opportunities: PropertyType[];
};

const getSampleOpportunities = (i: number) => {
  const {
    image,
    matchPercentage,
    price,
    marketValue,
    noiPercentage,
    avgRent,
    category,
  } = sampleOpportunities[i % sampleOpportunities.length];
  return {
    image,
    matchPercentage,
    price,
    marketValue,
    noiPercentage,
    avgRent,
    category,
  };
};

export const labeledZenOpportunitiesListView = {
  ZenOpportunitiesListView: () => {
    // const [selectedCategory, setSelectedCategory] =
    //   useState<PropertyListCategory>("new");
    // const [selectedOppIds, setSelectedOppIds] = useState<Set<string>>(
    //   new Set(),
    // );
    // const visibleOpps = opportunities.filter(
    //   (opp) => opp.category === selectedCategory,
    // );
    return (
      <SiteContainer>
        <SiteNav site="zen" />
        <SectionContainer>
          <SectionNavContainer />
          <main></main>
        </SectionContainer>
      </SiteContainer>
    );
  },
};

export const zenOpportunitiesListPage = () =>
  createKaiizenSsrPage({
    route: "/",
    load: async ({ deps: { db } }): Promise<LoaderResult> => {
      const properties = await db
        .getRepository(PropertySnapshot)
        .find({ take: 100 });

      return {
        opportunities: properties.map((prop, i) => ({
          id: prop.propertyId,
          address: `${prop.houseNumber} ${prop.street}`,
          city: "",
          state: "",
          postCode: "",
          msa: `${prop.regionMsaCode}`,
          details: {
            bedrooms: prop.bedCount,
            baths: prop.bathCount,
            sqft: prop.sqft,
            year: prop.year,
          },
          ...getSampleOpportunities(i),
        })),
      };
    },
    labeledView: labeledZenOpportunitiesListView,
  });
