import { StrictMode } from "react";
import { ServerStatePayload, ServerStateProvider } from "./ServerStateProvider";
import Cookies from "universal-cookie";
import { CookiesProvider } from "react-cookie";
import { MaterialUiProvider } from "./MaterialUiProvider";
import { useViewStateDirect } from "./ServerStateProvider";

type AppProps = {
  View: React.FC;
  serverState: ServerStatePayload;
  cookies?: Cookies; // will be undefined client-side, hydrating from browser
};

const AppViewWithViewState = ({ View }: { View: React.FC }) => {
  const viewState: any = useViewStateDirect<any>();
  return <View {...viewState} />;
};

export function App({ View, serverState, cookies }: AppProps) {
  return (
    <MaterialUiProvider>
      <StrictMode>
        <CookiesProvider cookies={cookies}>
          <ServerStateProvider value={serverState}>
            <div
              id="app-metadata"
              style={{ display: "none" }}
              data-user={serverState.user}
              data-version={serverState.version}
            ></div>
            <AppViewWithViewState View={View} />
          </ServerStateProvider>
        </CookiesProvider>
      </StrictMode>
    </MaterialUiProvider>
  );
}
