export const formatRoomCount = (roomCount?: number | string) => {
  if (!roomCount) return 0;

  const count = roomCount.toString();

  if (count.endsWith(".00")) {
    return count.substring(0, count.indexOf("."));
  } else if (count.endsWith(".50")) {
    return count.substring(0, count.length - 1);
  } else {
    return count;
  }
};

export const formatPrice = (price: number) => {
  const stringPrice = price.toString();

  if (stringPrice.length === 6 && stringPrice.endsWith("000")) {
    return `$${stringPrice.substring(0, 3)}K`;
  } else {
    return `$${price.toLocaleString()}`;
  }
};

export const sqftToAcres = (sqft?: number) => {
  if (!sqft) return 0;

  // turn 0.N acres into .N acres
  return (sqft / 43560).toFixed(2).replace(/^0./, ".");
};
