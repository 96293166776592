import { createTRPCClient, httpBatchLink } from "@trpc/client";
import { ZenApiRouter } from "./zenApi";
//*********************************************************************
// TRPC CLIENT
//*********************************************************************
export const zenApiClient = createTRPCClient<ZenApiRouter>({
  links: [
    httpBatchLink({
      url: "/api",
    }),
  ],
});
