export const Chevron = ({
  className,
  fillClass,
}: {
  className?: string;
  fillClass?: string;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="24"
      viewBox="0 0 10 24"
      fill=""
    >
      <path
        d="M7.71977 6.71001C7.34601 6.32001 6.74227 6.32001 6.36852 6.71001L1.96976 11.3C1.59601 11.69 1.59601 12.32 1.96976 12.71L6.36851 17.3C6.74226 17.69 7.34601 17.69 7.71976 17.3C8.09351 16.91 8.09351 16.28 7.71976 15.89L4.00143 12L7.71976 8.12001C8.09351 7.73001 8.08393 7.09001 7.71977 6.71001Z"
        fill={fillClass}
      />
    </svg>
  );
};
