import { PropertySnapshot } from "~/lib/models/PropertySnapshot";
import { formatRenoArray, PropertyAccordion } from "~/clients/zen/partials";
import { PropertyDetailPhoto } from "~/app/pages/opportunities/types";
import {
  accordionPhotoState,
  DetailScreen,
  filterDetailPhotos,
} from "~/clients/zen/partials/property/OverviewDetail";
import {
  detailArrayAsString,
  detailIsIncluded,
} from "~/clients/zen/pages/ZenPropertyDetail";
import { useState } from "react";
import { PhotoNavigator } from "~/react/components/property/photo-navigator/PhotoNavigator";

export const MiscellaneousDetail = ({
  property,
  photos,
  renoTotal,
}: {
  property: PropertySnapshot;
  photos: PropertyDetailPhoto[];
  renoTotal: number[];
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  const { state: propVals } = property;
  const filteredPhotos = filterDetailPhotos(photos, "miscellaneous");
  const getMiscPhotoState = accordionPhotoState(filteredPhotos, setPhotoIndex);

  return (
    <DetailScreen className="container">
      <h4>Miscellaneous</h4>
      <div className="wrap">
        <div className="left">
          <PropertyAccordion
            title="Permits"
            rows={[
              {
                name: "Estimated Reno Cost",
                value: formatRenoArray(
                  propVals.permitsRenoEstimateRangeDollars,
                ),
              },
            ]}
          />
          {detailIsIncluded("environmental", propVals) && (
            <PropertyAccordion
              title="Environmental"
              photoState={getMiscPhotoState("miscellaneous/environmental")}
              titleValue={detailArrayAsString(propVals.environmentalIssues)}
              rows={[
                {
                  name: "Type",
                  value: propVals.environmentalIssues,
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.environmentalRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          <PropertyAccordion
            title="Zoning"
            rows={[
              {
                name: "Estimated Reno Cost",
                value: formatRenoArray(propVals.zoningRenoEstimateRangeDollars),
              },
            ]}
          />
          {propVals.kaiizenInternalNotes && (
            <PropertyAccordion
              title="Additional Notes"
              rows={[
                {
                  name: null,
                  value: propVals.kaiizenInternalNotes,
                },
              ]}
            />
          )}
          <PropertyAccordion
            title="Total Miscellaneous Reno"
            titleValue={formatRenoArray(renoTotal)}
            rows={
              [
                // { name: "Total Estimated Timeline", value: "xxx days" },
              ]
            }
          />
        </div>
        <div className="right">
          <PhotoNavigator
            photos={photos}
            currentIndex={photoIndex}
            onPhotoChangeClick={setPhotoIndex}
            photoBackgroundSize={"contain"}
            paginationAlign={"right"}
            filteredPhotos={filteredPhotos}
            category={"miscellaneous"}
            onSeeAllClick={() => {
              window.location.assign(`/property/${property.propertyId}/photos`);
            }}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
