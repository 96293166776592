import { z } from "zod";
import { SsrPage, SsrViewMap } from "./ssrTypes";
import {
  ComponentProps,
  SsrLoaderResponse,
  SsrPageDefinition,
  SsrLoader,
} from "./ssrTypes";

const defaultLoader = (): ComponentProps => ({});

export function createSsrPage<
  T extends SsrLoaderResponse,
  Deps,
  InputSchema extends z.ZodSchema<any> | undefined,
>({
  route,
  load,
  input,
  labeledView,
}: SsrPageDefinition<T, Deps, InputSchema>): SsrPage<T, Deps, InputSchema> {
  const [viewKey, ...rest] = Object.keys(labeledView);
  if (rest.length !== 0)
    throw new Error("labeledView must have exactly one view");

  return {
    route,
    load: load || (defaultLoader as SsrLoader<T, Deps, InputSchema>),
    input,
    viewKey,
    view: labeledView[viewKey],
  };
}

export const safeViewMerge = (labeledViews: SsrViewMap[]): SsrViewMap =>
  labeledViews.reduce((acc, labeledView) => {
    const keys = Object.keys(labeledView);
    // find
    const duplicateKeys = keys.filter((key) => acc[key]);
    if (duplicateKeys.length > 0)
      throw new Error(`Duplicate view keys: ${duplicateKeys.join(", ")}`);
    return { ...acc, ...labeledView };
  }, {});
