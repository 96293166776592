import styled from "@emotion/styled";
import React from "react";

export const FormContainer = styled.div`
  background-color: white;
  border-radius: 0.5em;
  padding: 0.5em 1em;
`;

const Row = styled.label`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 0.5em 0;

  &.valign-top {
    align-items: flex-start;
  }
`;
const Label = styled.div`
  flex: 1 0 12em;
  font-weight: 700;
`;
const Field = styled.div`
  flex: 10 0 20em;
`;

type FormLabelProps = {
  valign?: "baseline" | "top";
  label: React.ReactNode;
  help?: React.ReactNode;
  children?: React.ReactNode;
};
export function FormField({ valign, label, help, children }: FormLabelProps) {
  const vertAlign = valign ?? "baseline";

  return (
    <Row className={`valign-${vertAlign}`}>
      <Label>{label}</Label>
      <Field>
        {children}
        <FieldHelp>{help}</FieldHelp>
      </Field>
    </Row>
  );
}

export const FieldHelp = styled.div`
  max-width: 600px;
  font-style: italic;
  font-size: 0.8em;
`;

export const TextInput = styled.input`
  outline: transparent solid 2px;
  outline-offset: 2px;
  appearance: none;
  border-radius: 16px;
  font-size: 1em;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-image: none;
  color: #1b2559;
  border-color: #e0e5f2;
  padding: 0.5em 0.8em;
  margin-bottom: 0.5em;
  font-weight: 500;

  &:disabled {
    background-color: #f7f8fc;
    color: #a6b0d0;
  }
`;
