import { PropertyAccordion } from "~/clients/zen/partials";
import { PropertySnapshot } from "~/lib/models/PropertySnapshot";
import { PropertyMap } from "~/clients/zen/partials/PropertyMap";
import { PropertyDetailPhoto } from "~/app/pages/opportunities/types";
import styled from "@emotion/styled";
import { PhotoNavigator } from "~/react/components/property/photo-navigator/PhotoNavigator";
import { useState } from "react";
import colors from "~/react/utilties/colors";
import { PhotoCategoryType } from "~/lib/uiTypes";

export const DetailScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 20px;

  > h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    padding: 25px 0;

    &:after {
      background-color: ${colors["gray-divider"]};
      content: " ";
      display: block;
      height: 1px;
      position: relative;
      top: 20px;
      width: calc(50% - 20px);
    }
  }

  .wrap {
    display: flex;
    flex-grow: 1;
    gap: 20px;
    overflow: hidden;

    > div {
      flex-grow: 1;
      width: 50%;
    }
  }

  .left {
    padding-top: 20px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;

    > div {
      flex-basis: 50%;
    }

    > div.map-controls,
    > div.no-images {
      flex-basis: 100%;
    }
  }
`;

export const filterDetailPhotos = (
  photos: PropertyDetailPhoto[],
  section: PhotoCategoryType,
  detail?: string,
) => {
  let filteredPhotos = photos.filter((photoObj) =>
    photoObj.tags.includes(section),
  );

  if (detail) {
    filteredPhotos = filteredPhotos.filter((photoObj) =>
      photoObj.tags.includes(section + "/" + detail),
    );
  }

  return filteredPhotos.sort((a, b) => a.tags[0].localeCompare(b.tags[0]));
};

export const accordionPhotoState =
  (photos: PropertyDetailPhoto[], clickHandler: (index: number) => void) =>
  (detail: string) => {
    const filtered = photos.filter((photo) => photo.tags.includes(detail));

    if (filtered.length === 0) return null;

    const firstIndex = photos.findIndex((photo) => photo.tags[0] === detail);

    return {
      count: filtered.length,
      onClick: () => {
        clickHandler(firstIndex);
      },
    };
  };

export const OverviewDetail = ({
  property,
  photos,
  mapboxKey,
  kaiScanDate,
  listDate,
}: {
  property: PropertySnapshot;
  photos: PropertyDetailPhoto[];
  mapboxKey: string;
  kaiScanDate: string | null;
  listDate: string | null;
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  let daysOnMarket;

  if (listDate === null) {
    daysOnMarket = "--";
  } else if (parseInt(listDate) === 0) {
    daysOnMarket = 1;
  } else {
    daysOnMarket = listDate;
  }

  return (
    <DetailScreen className="container">
      <h4>Overview</h4>
      <div className="wrap">
        <div className="left">
          <PropertyAccordion
            title={"Details"}
            expandedDefault={true}
            rows={[
              { name: "Status", value: "Active" },
              // { name: "Exclusivity Days Remaining", value: "XX Days" },
              { name: "Days on Market", value: daysOnMarket + " Days" },
              // { name: "Est. Time to Sell on Zen", value: "XX Days" },
              {
                name: "Kai Walkthrough",
                value: kaiScanDate
                  ? new Date(kaiScanDate).toLocaleDateString("en-US")
                  : "--",
              },
              { name: "Zen Buyer Interest", value: "High" },
              { name: "Sold as", value: `${property.state.soldAs} sale` },
            ]}
          />
          <PropertyAccordion
            title="Summary"
            rows={[
              { name: null, value: property.state.propertyOverviewDescription },
            ]}
          />
        </div>
        <div className="right">
          <PhotoNavigator
            photos={photos}
            currentIndex={photoIndex}
            onPhotoChangeClick={setPhotoIndex}
            photoBackgroundSize={"contain"}
            paginationAlign={"right"}
            onSeeAllClick={() => {
              window.location.assign(`/property/${property.propertyId}/photos`);
            }}
          />

          <PropertyMap
            apiKey={mapboxKey}
            coords={property.state.coordinates || [0, 0]}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
