import styled from "@emotion/styled";
import { createPortal } from "react-dom";

const DialogDiv = styled.div`
  background-color: #fff;
  border-radius: 30px;
  margin: 0 auto;
  max-width: 510px;
  padding: 50px;
  position: relative;
  top: 15%;
`;

export const Modal = ({
  modalHook,
  children,
}: {
  modalHook: any;
  children: any;
}) => {
  return (
    modalHook.isVisible &&
    createPortal(
      <DialogDiv className="modal-dialog" ref={modalHook.modalRef}>
        {children}
      </DialogDiv>,
      document.querySelector("#modal-root") || document.body,
    )
  );
};
